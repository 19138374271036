
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Edit Series Form</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a [routerLink]="['/admin/series/list',user_id]">Series</a></li>
                                <li class="breadcrumb-item active">edit</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-10">

                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Edit Series</h3>
                                </div>
                                <div class="card-body">
                                    <form #routineForm="ngForm" (ngSubmit)="routineSubmit(routineForm)">
                                        <div class="form-group">
                                            <label>Series Name:</label>
                                            <input type="text" required name="routine_name" [(ngModel)]="model.routine_name" #routine_name="ngModel" class="form-control" placeholder="routine name">
                                            <div class="alert-danger" *ngIf="(routine_name.touched && !routine_name.valid)||(routineForm.submitted && !routine_name.valid)">
                                                <div *ngIf="routine_name.errors.required">Routine name is required.</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Series Description:</label>
                                            <input type="text" required name="routine_description" [(ngModel)]="model.routine_description" #routine_description="ngModel" class="form-control" placeholder="routine description">
                                            <div class="alert-danger" *ngIf="(routine_description.touched && !routine_description.valid)||(routineForm.submitted && !routine_description.valid)">
                                                <div *ngIf="routine_description.errors.required">Routine description is required.</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Content type :</label>
                                            <select required name="content_type" [(ngModel)]="model.content_type" #content_type="ngModel" class="text-row form-control">
                                                <option value="premium">Premium</option>
                                                <option value="free">Free</option>
                                            </select>
                                            <div class="alert-danger" *ngIf="(content_type.touched && !content_type.valid)||(routineForm.submitted && !content_type.valid)">
                                                <div *ngIf="content_type.errors.required">Content type is required.</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Series level:</label>
                                            <!-- <input type="text" required name="routine_level" [(ngModel)]="model.routine_level" #routine_level="ngModel" class="form-control" placeholder="Routine level"> -->
                                            <select  required name="routine_level" [(ngModel)]="model.routine_level" #routine_level="ngModel" class="form-control">
                                                <option value="">Select Level</option>
                                                <option value="0">Beginner</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <div class="alert-danger" *ngIf="(routine_level.touched && !routine_level.valid)||(routineForm.submitted && !routine_level.valid)">
                                                <div *ngIf="routine_level.errors.required">Series level is required.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label>Routine level:</label>
                                            <input type="text" required name="routine_level" [(ngModel)]="model.routine_level" #routine_level="ngModel" class="form-control" placeholder="routine level">
                                            <div class="alert-danger" *ngIf="(routine_level.touched && !routine_level.valid)||(routineForm.submitted && !routine_level.valid)">
                                                <div *ngIf="routine_level.errors.required">Routine level is required.</div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="form-group">
                                            <label>Artist:</label>
                                            
                                            <select required name="user_id" class="form-control"  style="width: 100%;" data-select2-id="1" (change)="onGetUserId($event.target.value)"  aria-hidden="true">
                                                <option value="">Select Artist</option>
                                                <option [selected]="model.user_id == artist.id" *ngFor="let artist of artistList" value="{{artist.id}}">{{artist.fullname}}</option>
                                            </select> -->
                                            <!-- <div class="alert-danger" *ngIf="(user_id.touched && !user_id.valid)">
                                                <div *ngIf="user_id.errors.required">Artist is required.</div>
                                            </div> -->
  
                                        <!-- </div> -->
                                        <div class="bootstrap-timepicker">
                                            <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px">
                                            <div class="form-group">
                                                <label>Profile:</label>
                                                <div class="input-group date">
                                                    <input  accept="image/*"  type="file" class="form-control" (change)="onFileChange($event)"
                                                       />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" [class.disabled]="!routineForm.valid" class="btn btn-primary">Save
                                                <span *ngIf="showLoader"> <i class="fas fa-sync fa-spin"></i></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       