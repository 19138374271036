<!-- <body class="hold-transition sidebar-mini layout-fixed">
    <div class="wrapper"> -->
    
      <!-- Navbar -->
     
      <!-- /.navbar -->
    
      <!-- Main Sidebar Container -->
    
    
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Dashboard</h1>
              </div><!-- /.col -->
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/admin/dashboard" >Home</a></li>
                  <li class="breadcrumb-item active">Dashboard</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
    
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid dash-box">
            <!-- Small boxes (Stat box) -->
            <div class="row">

              <div class="col-lg-6 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{{allCounts.artist_count}}<sup style="font-size: 20px"></sup></h3>
                    <p>Total Artists</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a routerLink="/admin/artist/list" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> 
                </div>
              </div>

              <div class="col-lg-6 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>{{allCounts.user_count}}</h3>
                    <p>Total Users</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a routerLink="/admin/user/list" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div> 
              </div>
              
              <div class="col-lg-6 col-6">
                <!-- small box -->
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{{allCounts.video_count}}</h3>
                    <p>Total Videos</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a routerLink="/admin/artist/list" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              <!-- ./col -->
             
              <!-- ./col -->
           
              <!-- ./col -->
              <div class="col-lg-6 col-6">
                <!-- small box -->
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{{allCounts.routine_count}}</h3>
                    <p>Total Routines</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i> 
                  </div>
                  <a routerLink="/admin/artist/list"  class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
            </div>
            <div class="row">
              <section class="col-lg-7 connectedSortable">
              </section>
            </div>
          </div>
        </section>
      </div>
     
      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
    <!-- </div>
    
    
    </body> -->