<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Series List</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/artist/list']">Artist List</a></li>
                        <li class="breadcrumb-item"><a class="btn btn-sm btn-primary btn-block"
                                [routerLink]="['/admin/series/add',user_id]">Add Series</a></li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Series List</h3>
                            <div class="card-tools">
                                <button  (click)="resetSearch()" id="search_refresh" type="button"  class="btn btn-primary">
                                    <i class="fas fa-redo-alt"></i>
                                  </button>
                                <div class="input-group input-group-sm" style="width: 160px;">
                                    <input type="text" name="table_search" class="table_search form-control float-right"
                                        placeholder="Search By Name">
                                    <div class="input-group-append">
                                        <button (click)="routneList(1)" type="button" class="btn btn-default">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table datatable class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Artist Name</th>
                                            <th>Series Name</th>
                                            <th>Content Type</th>
                                            <th>Series Description</th>
                                            <th>Series Level</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngIf="showLoader">
                                            <td class="loader-td" colspan="8">
                                                <i class="fas 3x fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                        <tr id="row_{{series.id}}" *ngFor="let series of List | paginate: { itemsPerPage: 50, currentPage: currentPage, id: 'second' ,totalItems: total}; let i = index">
                                            <td>{{(i+1)+(50*(currentPage-1))}}</td>
                                            <td>{{series.user.fullname}}</td>
                                            <td>{{series.series_name}}</td>
                                            <td>{{series.content_type}}</td>
                                            <td class="description">{{series.series_description}}</td>
                                            <td>{{(series.series_level==0)?'Beginner':series.series_level}}</td>
                                            <td><img src="{{series.image}}" width="100px" height="50px"></td>
                                            <td>
                                                <a class="btn btn-sm btn-outline-primary" [routerLink]="['/admin/series/view',series.id]"><i class="fas fa-eye"></i></a>
                                                <a class="btn btn-sm btn-outline-danger" (click)="deleteRoutine(series.id)"><i class="fas fa-trash"></i></a>
                                                <a class="btn btn-sm btn-outline-info" [routerLink]="['/admin/series/edit',series.id,series.user_id]"><i class="fas fa-pencil-alt"></i></a>
                                                <a class="btn btn-sm btn-primary" [routerLink]="['/admin/series-video/list/', series.id , series.user_id]">Videos<span class="badge bg-warning">{{series.video_count}}</span></a>
                                            </td>

                                        </tr>
                                        <tr *ngIf="List?.length == 0" class="alert-danger">
                                            <td class="message-td" colspan="8">
                                                <span>
                                                    No data found
                                                </span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination-controls (pageChange)="chandlePageChange($event)" id="second"></pagination-controls>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>