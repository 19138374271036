<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Change Password Form</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Change Password</h3>
                        </div>
                        <div class="card-body">
                            <form #changePasswordForm="ngForm" (ngSubmit)="PasswordFormSubmit(changePasswordForm)">
                                <div class="form-group">
                                    <label>Current Password:</label>
                                    <input type="text" (change) ="checkPasswordRunTime()" required name="current_password" [(ngModel)]="model.current_password" #current_password="ngModel"
                                        class="form-control" placeholder="Password">
                                        <div class="alert-danger" *ngIf="current_password.touched && !current_password.valid">
                                            <div *ngIf="current_password.errors.required">Current Password is required.</div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <label>New Password:</label>
                                    <input type="text" required name="new_password" [(ngModel)]="model.new_password" #new_password="ngModel"
                                        class="form-control" placeholder="New Password">
                                        <div class="alert-danger" *ngIf="new_password.touched && !new_password.valid">
                                            <div *ngIf="new_password.errors.required">New Password is required.</div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <label>Confirm Password:</label>
                                    <input type="text" required name="confirm_password" [(ngModel)]="model.confirm_password" #confirm_password="ngModel"
                                        class="form-control" placeholder="Confirm Password">
                                        <div class="alert-danger" *ngIf="confirm_password.touched && !confirm_password.valid">
                                            <div *ngIf="confirm_password.errors.required">Confirm Password is required.</div>
                                        </div>
                                </div>
                            
                                <div class="form-group">
                                    <button type="submit" [class.disabled]="!changePasswordForm.valid" class="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>