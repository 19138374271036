<body class="hold-transition login-page">
    <div class="login-box">
        <div class="login-logo">
            <a href="javascript:void(0)"><b><img src="http://upperneighbormusic.com/upperneighbour_admin/uploads/Logo.png" alt="upperneigher"></b></a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Forgot Password</p>
                <form #forgotPssword="ngForm" (ngSubmit)="forgotPassword(forgotPssword)">
                    <div class="input-group mb-3">
                        <input type="email" [pattern]="emailPattern" required name="email" [(ngModel)]="model.email" #email="ngModel" class="form-control" placeholder="Email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="alert-danger" *ngIf="email.touched && !email.valid">
                        <div *ngIf="email.errors.required">Email is required.</div>
                        <div *ngIf="email.errors.pattern">Email must be a valid email address</div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="submit" [class.disabled]="!forgotPssword.valid" class="btn btn-primary btn-block">submit
                                <span *ngIf="showLoader"> <i class="fas fa-sync fa-spin"></i></span>
                            </button>
                        </div>
                        <div class="col-6">
                            <a class="btn btn-outline-primary btn-block log-Btn" routerLink="/login">Login</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</body>