
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Blog View</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a routerLink="/admin/blog/list">Blog</a></li>
                                <li class="breadcrumb-item active">blog view</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Detail</h3>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <strong>Title:</strong><p>{{blogDetail.title}}</p>
                                    </div>
                                     <hr />
                                    <!-- <div>
                                        <strong>Description:</strong><p>{{blogDetail.description}}</p>
                                    </div>
                                    <hr /> -->
                                    <div>
                                        <strong>Url:</strong><p>{{blogDetail.url}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>Image:</strong><p> <img [src]="imageSrc"  style="height: 100px; width:100px"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       