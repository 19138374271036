
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Edit Routine Video Form</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a [routerLink]="['/admin/routine-video/list',routine_id , user_id]">Routine Video list</a></li>
                                <li class="breadcrumb-item active">edit</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Edit Routine Video</h3>
                                </div>
                                <div class="card-body">
                                    <form #routineForm="ngForm" (ngSubmit)="routineSubmit(routineForm)">
                                        <div class="form-group">
                                            <label>Video Title:</label>
                                            <input type="text" required name="video_title" [(ngModel)]="model.video_title" #video_title="ngModel" class="form-control" placeholder="video title">
                                            <div class="alert-danger" *ngIf="(video_title.touched && !video_title.valid)||(routineForm.submitted && !video_title.valid)">
                                                <div *ngIf="video_title.errors.required">Routine name is required.</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Video Description:</label>
                                            <input type="text" required name="video_description" [(ngModel)]="model.video_description" #video_description="ngModel" class="form-control" placeholder="video description">
                                            <div class="alert-danger" *ngIf="(video_description.touched && !video_description.valid)||(routineForm.submitted && !video_description.valid)">
                                                <div *ngIf="video_description.errors.required">Routine description is required.</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            Content type :
                                            <select name="content_type" [(ngModel)]="model.content_type" #content_type="ngModel" class="text-row form-control">
                                                <option value="premium">Premium</option>
                                                <option value="free">Free</option>
                                            </select>
                                        </div>
                                        <div class="bootstrap-timepicker">
                                            
                                            <div class="form-group" *ngIf="this.model.video_type!='embed_url'">
                                                <label>Video:</label>
                                                <div class="input-group date">
                                                    <input  accept="video/*" type="file" class="form-control" (change)="onFileChange($event)"
                                                       />
                                                </div>
                                            </div>

                                            <div class="form-group" *ngIf="this.model.video_type=='embed_url'">
                                                <label>Embed URL:</label>
                                                <div class="input-group date">
                                                    <input type="url" class="form-control" name="embed_url" [(ngModel)]="model.embed_url" #video_title="ngModel"/>
                                                </div>
                                            </div>
                                            <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px">
                                            <div class="form-group" *ngIf="this.model.video_type=='embed_url'">
                                                <label>Video Thumb:</label>
                                                <div class="input-group date">
                                                    <input  accept="images/*" type="file" class="form-control" (change)="onThumbFileChange($event)"
                                                       />
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <button type="submit" [class.disabled]="!routineForm.valid" class="btn btn-primary">Save
                                                    <span *ngIf="showLoader"> <i class="fas fa-sync fa-spin"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       