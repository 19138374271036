
        <div class="">
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-info">
                                <div class="card-header">
                                    <h3 class="card-title">Privacy Policy</h3>
                                </div>
                                <div class="card-body">
                                    A Privacy Policy is a statement or declaration that explicitly details your company policy regarding how you handle user and customer information.

                                    This applies to any information collected from website visitors or customers that could be deemed personal and could potentially be used to identify them as individuals. Personal information includes things like:
                                    
                                    First and last names
                                    Birthdays
                                    Email addresses
                                    Phone numbers
                                    Credit card information
                                    Social Security numbers
                                    Home and/or business addresses
                                    Sensitive information (regarding an individual's race, ethnicity, religious beliefs, political opinion, sexual orientation or criminal record)
                                    Consumers have a basic right to be given detailed knowledge of the ways a company intends to use any personal data collected from or about them.
                                    
                                    While this has the potential to impact their decision to share certain information, the rise in worldwide online data sharing has created a much more dire need for transparency between consumer and company.
                                    
                                    In the past, you may have thought that a Privacy Policy was an unnecessary piece of legal jargon that no one actually paid much attention to. While that may have been true, consumers are becoming a lot more protective over their online personal data - and rightly so.
                                    
                                    Misuse of consumer personal data can lead to a number of security concerns, such as personal identity theft, banking and financial theft, credit card scams and more. Keeping consumer data safeguarded against risks such as these has become a legal mandate.
                                    
                                    Privacy Policies are a great way to protect both your users and your company from any security concerns. They also provide a solid reason for consumers to feel like they can trust your company, which is an essential aspect of online business.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       