
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Edit Blog Form</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a routerLink="/admin/blog/list">Blog</a></li>
                                <li class="breadcrumb-item active">Edit blog</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Edit Blog</h3>
                                </div>
                                <div class="card-body">
                                    
                                    <form #blogForm="ngForm" (ngSubmit)="blogSubmit(blogForm)">
                                        <div class="form-group">
                                            <label>Title:</label>
                                            <input type="text"  required name="title" [(ngModel)]="model.title" #title="ngModel" class="form-control" placeholder="title">
                                            <div class="alert-danger" *ngIf="(blogForm.submitted && !title.valid)">
                                                <div *ngIf="title.errors.required">Title is required.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label>Description:</label>
                                            <div class="input-group">
                                                <ckeditor name="editorData" [(ngModel)]="model.editorData" [editor]="Editor"></ckeditor>
                                            </div>
                                            <div class="alert-danger" *ngIf="blogForm.submitted && !model.editorData">
                                                <div >Description is required.</div>
                                            </div>
                                        </div> -->
                                        <div class="bootstrap-timepicker">
                                            <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px">
                                            <div class="form-group">
                                                <label>Image:</label>
                                                <div class="input-group date">
                                                    <input accept="image/*" type="file" class="form-control" (change)="onFileChange($event)"
                                                       />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Url:</label>
                                                <input type="text" required name="url" [(ngModel)]="model.url"
                                                    #url="ngModel" class="form-control" placeholder="url">
                                                <div class="alert-danger" *ngIf="(blogForm.submitted && !url.valid)">
                                                    <div *ngIf="url.errors.required">URL is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" [class.disabled]="!blogForm.valid" class="btn btn-primary">Save
                                                <span *ngIf="showLoader"> <i class="fas fa-sync fa-spin"></i></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       