
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Update Settings Form</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="card card-info">
                                <div class="card-header">
                                    <h3 class="card-title">Update Settings</h3>
                                </div>
                                <div class="card-body">
                                    <form #setting="ngForm" (ngSubmit)="settingSubmit(setting)">
                                      
                                        <table class="table table-bordered setting-form">
                                          <tr >
                                            <td>
                                                <label>Key :</label>
                                                <input type="text" [disabled]="true"  value="Slice user name" class="text-row form-control">
                                            </td>
                                            <td>
                                                <label>Value :</label>
                                                <input type="text" required name="slice_user_name" [(ngModel)]="model.slice_user_name" #slice_user_name="ngModel" class="form-control" placeholder="slice username">
                                               
                                            </td>
                                          </tr>
                                          <tr >
                                            <td>
                                               <label>Key :</label>
                                                <input type="text" [disabled]="true" readonly  value="Slice Password" class="text-row form-control">
                                            </td>
                                            <td>
                                               <label>Value :</label>
                                                <input type="text" required name="slice_password" [(ngModel)]="model.slice_password" #slice_password="ngModel" class="form-control" placeholder="slice password">
                                                
                                            </td>
                                          </tr>
                                        </table>
                                       
                                        <div class="form-group">
                                            <button type="submit"  class="btn btn-primary">Update
                                                <!-- <span > <i class="fas fa-sync fa-spin"></i></span> -->
                                            </button>
                                        </div>
                                        
                                      </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       