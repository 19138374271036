<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Artist Video List</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/artist/list']">Artist List</a></li>
                        <li class="breadcrumb-item"><a class="btn btn-sm btn-primary btn-block" [routerLink]="['/admin/artist-video/add', user_id]">Add Artist Video</a></li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Artist Video List</h3>
                            <div class="card-tools">
                                <button  (click)="resetSearch()" id="search_refresh" type="button"  class="btn btn-primary">
                                    <i class="fas fa-redo-alt"></i>
                                  </button>
                                <div class="input-group input-group-sm" style="width: 160px;">
                                    <input type="text" name="table_search" class="table_search form-control float-right"
                                        placeholder="Search By Title">
                                    <div class="input-group-append">
                                        <button (click)="artistVideoList(1)" type="button" class="btn btn-default">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table datatable class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Drag Icon</th>
                                            <th>Artist Name</th>
                                            <th>Content Type</th>
                                            <th>Video Title</th>
                                            <th>Video Level</th>
                                            <th>Duration</th>
                                            <th>Description</th>
                                            <th>Thumb</th>
                                            <th>Add to SoundSlice</th>
                                            <th >Add Notation File to SoundSlice</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody cdkDropList class="example-list mt-20" (cdkDropListDropped)="drop($event)">

                                        <tr *ngIf="showLoader">
                                            <td class="loader-td" colspan="9">
                                                <i class="fas 3x fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>

                                        <tr cdkDrag id="row_{{routine.id}}" *ngFor="let routine of List | paginate: { itemsPerPage: 50, currentPage: currentPage, id: 'second' ,totalItems: total }; let i = index">
                                            <td>{{(i+1)+(50*(currentPage-1))}}</td>
                                            <td>
                                                <div class="w-10 drag-icon">
                                                  <i class="fas fa-bars"></i>
                                                </div>
                                            </td>
                                            <td>{{routine.user.fullname}}</td>
                                            <td>{{routine.content_type}}</td>
                                            <td>{{routine.video_title}}</td>
                                            <td>{{(routine.video_level==0)?'Beginner':routine.video_level}}</td>
                                            <td>{{routine.duration}}</td>
                                            <td>{{routine.video_description}}</td>
                                            <td><img src="{{routine.video_thumb}}" width="100px" height="50px"></td>

                                            <td>
                                            <button id="slice_{{routine.id}}" *ngIf="routine.slice_added == 'no'" class="btn btn-primary" 
                                                (click)="createVideoSliceRecordong(routine.id , routine.user.routineFolder.folder_info.id)">Add Slice<span id="loader_{{routine.id}}" style="display: none;"> <i
                                                    class="fas fa-sync fa-spin"></i></span></button>
                                            <button id="slice_dis_{{routine.id}}" [disabled]="true"  *ngIf="routine.slice_added == 'yes'" class="btn btn-primary">
                                                                 Added<span
                                                class="badge bg-warning"></span></button>
                                        </td>

                                         <td class="last-artist-video">
                                            <form #notation ="ngForm" id="form_{{routine.id}}">
                                                <div class="input-group date">
                                                    <input id="notation_file" type="file" class="form-control"
                                                        (change)="onFileChange($event,routine.id)" />
                                                </div>
                                                <div class="alert-danger" *ngIf="notation.submitted && !notationFile">
                                                    <div>Notation File is required.</div>
                                                </div>
                                                
                                                <button id="notation_{{routine.id}}" *ngIf="routine.notation_file_added == 'no'" class="btn btn-primary" [class.disabled]="!notation.valid"
                                                    (click)="addSliceNotation()">Add Notation<span id="loader_notation_{{routine.id}}" style="display: none;"> <i
                                                        class="fas fa-sync fa-spin"></i></span>
                                                </button>
                                               <button type="button" (click)="addNotation(notation)" id="notation_dis_{{routine.id}}" [disabled]="true"  *ngIf="routine.notation_file_added == 'yes'" class="btn btn-primary">
                                                            Added</button>
                                            </form>
                                        </td>
                                            <td>
                                                <a class="btn btn-sm btn-outline-primary" [routerLink]="['/admin/artist-video/view',routine.id]"><i class="fas fa-eye"></i></a>
                                                <a class="btn btn-sm btn-outline-danger" (click)="deleteArtistVideo(routine.id)"><i class="fas fa-trash"></i></a>
                                                <a class="btn btn-sm btn-outline-info" [routerLink]="['/admin/artist-video/edit',routine.id]"><i class="fas fa-pencil-alt"></i></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="List?.length == 0" class="alert-danger">
                                            <td class="message-td" colspan="10">
                                                <span>
                                                    No data found
                                                </span>
                                                
                                            </td>
                                            
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination-controls (click)="getCurrentPage()" (pageChange)="handlePageChange($event)" id="second"></pagination-controls>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>