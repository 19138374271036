<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Edit Profile</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Update Admin Profile</h3>
                        </div>
                        <div class="card-body">
                            <form #adminForm="ngForm" (ngSubmit)="adminSubmit(adminForm)">
                                <div class="form-group">
                                    <label>Full Name:</label>
                                    <input type="text" required name="fullname" [(ngModel)]="model.fullname" #fullname="ngModel" class="form-control" placeholder="Fullname">
                                    <div class="alert-danger" *ngIf="(fullname.touched && !fullname.valid)||(adminForm.submitted && !fullname.valid)">
                                        <div *ngIf="fullname.errors.required">Full Name is required.</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email:</label>
                                    <input [disabled]='true' type="text" required name="email" [(ngModel)]="model.email" #email="ngModel" class="form-control" placeholder="Email">
                                </div>
                                <div class="bootstrap-timepicker">
                                    <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px">
                                    <div class="form-group">
                                        <label>Profile:</label>
                                        <div class="input-group date">
                                            <input  accept="image/*"  type="file" class="form-control" (change)="onFileChange($event)"
                                               />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" [class.disabled]="!adminForm.valid" class="btn btn-primary">Save
                                        <span *ngIf="showLoader"> <i class="fas fa-sync fa-spin"></i></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>