<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a class="brand-link">
    <img src="https://upperneighbormusic.com/upperneighbour_admin/uploads/Logo.png" alt="upperneigher"
      class="brand-image" style="opacity: 1">
    <span class="brand-text font-weight-light">Upper Neighbor</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional, { type: 'intersection' },false)  -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img id="admin_profile" src="{{imageSrc}}" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info">
        <a id="admin_name" class="d-block">{{adminDetail.fullname}}</a>
      </div>
    </div>

    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item menu-open"> 
          <a routerLinkActive='active' routerLink="admin/dashboard" class="nav-link">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a  [class.active]="router.isActive('/admin/artist-video/edit',false)  || router.isActive('/admin/artist-video/view',false) || router.isActive('/admin/artist-video/add',false) || router.isActive('/admin/routine-video/add',false) || router.isActive('/admin/routine/add',false)  
          || router.isActive('/admin/artist-video/list',false)  || router.isActive('/admin/artist/list',false)  || router.isActive('/admin/routine-video/edit',false)  || router.isActive('/admin/artist/add',false) || router.isActive('/admin/artist/view',false) || router.isActive('/admin/artist/edit',false) 
          || router.isActive('/admin/routine-video/view',false)  || router.isActive('/admin/routine/list',false)  || router.isActive('/admin/routine/view',false)  || router.isActive('/admin/routine/edit',false)  || router.isActive('/admin/routine-video/list',false) "   routerLink="admin/artist/list"
            class="nav-link">
            <i class="nav-icon fas fas fa-user"></i>
            <p>
              Artists
            </p>
          </a>
        </li>
       
        <li class="nav-item">
          <a [class.active]="router.isActive('/admin/blog/edit',false)  || router.isActive('/admin/blog/view',false) || router.isActive('/admin/blog/add',false)  
          || router.isActive('/admin/blog/list',false) "
          routerLink="admin/blog/list"
            class="nav-link">
            <i class="nav-icon fas fas fa-book"></i>
            <p>
              Blog
            </p>
          </a>
        </li>
        <li class="nav-item">
          <!-- {{router.isActive('/admin/user/list',false)}} -->
          <!-- {{router.isActive(['null','/admin/user/list'],false)}} -->
          <a   [class.active]="router.isActive('/admin/user/edit',false)  || router.isActive('/admin/user/view',false)  
          || router.isActive('/admin/user/list',false) " routerLink="admin/user/list"
            class="nav-link">
            <i class="nav-icon fas fas fa-user"></i>
            <p>
              User
            </p>
          </a>
        </li>
        <!-- [class.active]="router.isActive('/admin/artist-video/edit',false)  || router.isActive('/admin/artist-video/view',false)  
          || router.isActive('/admin/artist-video/list',false)  || router.isActive('/admin/artist/list',false)  || router.isActive('/admin/routine-video/edit',false)  
          || router.isActive('/admin/routine-video/view',false)  || router.isActive('/admin/routine/list',false)  || router.isActive('/admin/routine/view',false)  || router.isActive('/admin/routine/edit',false)  || router.isActive('/admin/routine-video/list',false) " -->
        
       
        <!-- <li class="nav-item">
          <a [routerLinkActiveOptions]="{exact: false}" routerLinkActive='active' routerLink="admin/routine/list"
            class="nav-link">
            <i class="nav-icon fas fas fa-user"></i>
            <p>
              Routine
            </p>
          </a>
        </li> -->
        <li class="nav-item">
          <a  routerLinkActive='active' routerLink="admin/hashtag" class="nav-link">
            <i class="nav-icon fa fa-hashtag"></i>
           <p>
              Hashtags
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a routerLinkActive='active' routerLink="admin/edit-profile" class="nav-link">
            <i class="nav-icon fas fa-edit"></i>
            <p>
              Edit Profile
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a  routerLinkActive='active' routerLink="admin/change-password" class="nav-link">
            <i class="nav-icon fas fa-key"></i>
            <p>
              Change Password
            </p>
          </a>
        </li>
        
         <li class="nav-item menu-open">
          <a routerLinkActive='active' routerLink="admin/setting/edit" class="nav-link">
            <i class="nav-icon fas fa-cog"></i>
            <p>
              Settings
            </p>
          </a>
        </li> 

        <!-- <li class="nav-item" (click, { type: 'intersection' },false) ="clickEvent(, { type: 'intersection' },false) "  
    [ngClass]="status ? 'menu-is-opening menu-open' : ''">
            <a class="nav-link">
              <i class="nav-icon fas fa-edit"></i>
              <p>
                Forms
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" [ngClass]="status ? {'display':'block'} : {'display':'none'}"
             >
              <li class="nav-item">
                <a href="pages/forms/general.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>General Elements</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="pages/forms/advanced.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Advanced Elements</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="pages/forms/editors.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Editors</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="pages/forms/validation.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Validation</p>
                </a>
              </li>
            </ul>
          </li> -->

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>