
<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Artist Video View</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/artist-video/list',routineVideoDetail.user_id]">Artist Video list</a></li>
                        <li class="breadcrumb-item active">Artist Video view</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-info">
                        <div class="card-header">
                            <h3 class="card-title">Detail</h3>
                        </div>
                        <div class="card-body">
                            <div>
                                <strong>Title:    </strong><p>{{(routineVideoDetail.video_title)?routineVideoDetail.video_title:''}}</p>
                            </div>
                            <hr />
                            <div>
                                <strong>Description:   </strong><p>{{routineVideoDetail.video_description}}</p>
                            </div>
                            <hr />
                            <div>
                                <strong>Duration:  </strong><p>{{routineVideoDetail.duration}}</p>
                            </div>
                            <hr />
                            <div>
                                <strong>Artist:   </strong><p>{{routineVideoDetail.user.fullname}}</p>
                            </div>
                            <hr />
                            <div>
                                <strong class="thumb_strong">Thumb Image:  </strong><p class="thumb_image"><img [src]="model.video_thumb"  style="height: 100px; width:100px"></p>
                            </div>
                            <hr />
                            <div>
                                <strong>Video: </strong>
                                <div class="video" *ngIf="embed_video">
                                    <iframe [src]='video_link' width="300px" height="250px" frameborder="0"></iframe>
                                </div>
                                <div class="video" *ngIf="local_video">
                                    <video muted *ngIf="model.video_url" id="video1"  width="300px" height="195px" class="img" controls
                                    >
                                    <source  src="{{model.video_url}}" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
