<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>User List</h1>
                </div>
                <!-- <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a class="btn btn-primary btn-block" routerLink="/admin/artist/add">Add Artist</a></li>
                    </ol>
                </div> -->
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">User List</h3>
                            <div class="card-tools">
                                <button  (click)="resetSearch()" id="search_refresh" type="button"  class="btn btn-primary">
                                    <i class="fas fa-redo-alt"></i>
                                  </button>
                                <div class="input-group input-group-sm" style="width: 160px;">
                                  <input type="text" name="table_search" class="table_search form-control float-right" placeholder="Search By Name">
                                  <div class="input-group-append">
                                    <button  (click)="teacherList(1)" type="button" class="btn btn-default">
                                      <i class="fas fa-search"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table datatable class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Full Name</th>
                                            <th>Email</th>
                                            <th>Profile</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="showLoader">
                                            <td class="loader-td" colspan="5">
                                                <i class="fas 3x fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                  
                                        <tr id="row_{{teacher.id}}" *ngFor="let teacher of items | paginate: { itemsPerPage: 50, currentPage: currentPage, id: 'second' ,totalItems: total } ; let i = index">
                                            <td>{{(i+1)+(50*(currentPage-1))}}</td>
                                            <td>{{teacher.fullname}}</td>
                                            <td>{{teacher.email}}</td>
                                            <td><img src="{{teacher.profile}}" width="100px" height="50px"></td>
                                            <td>
                                                <label  class="switch">
                                                <input (click)="changeStatus(teacher.id,teacher.active_status)" [checked]="teacher.active_status" class="status_{{teacher.id}}" type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                            </td>
                                            <td>
                                                <a class="btn btn-sm btn-outline-primary" [routerLink]="['/admin/user/view',teacher.id]"><i class="fas fa-eye"></i></a>
                                                <a class="btn btn-sm btn-outline-danger" (click)="deleteTeacher(teacher.id)"><i class="fas fa-trash"></i></a>
                                                <a class="btn btn-sm btn-outline-info" [routerLink]="['/admin/user/edit',teacher.id]"><i class="fas fa-pencil-alt"></i></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="items.length == 0" class="alert-danger">
                                            <td class="message-td" colspan="6">
                                            <span >
                                            No data found 
                                            </span>
                                            </td>    
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination-controls  (pageChange)="chandlePageChange($event)" id="second"></pagination-controls>
                        <!-- <div  class="card-footer pb-0 pt-3">
                            <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>