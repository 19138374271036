
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Artist View</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a routerLink="/admin/artist/list">Artist</a></li>
                                <li class="breadcrumb-item active">Artist view</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Detail</h3>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <strong>FullName:</strong><p>{{teacherDetail.fullname}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>Profile:</strong><p> <img [src]="teacherDetail.profile"  style="height: 100px; width:100px"></p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>Instrument:</strong><p>{{teacherDetail.teacherProfile.profession}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>About:</strong><p>{{teacherDetail.teacherProfile.history}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>Career:</strong><p>{{teacherDetail.teacherProfile.career_highlight}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>My Kryptonite:</strong><p>{{teacherDetail.teacherProfile.key_point}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>Memorable Performanc:</strong><p>{{teacherDetail.teacherProfile.performance}}</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>address:</strong><p>{{teacherDetail.teacherProfile.address}}</p>
                                    </div>
                                    <div>
                                        <strong>Most Used Emojis:</strong><p><img *ngFor="let emojiObj of teacherDetail.teacherProfile.userEmojis ;" [src]="emojiObj.emoji"  style="height: 50px; width:50px"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       