<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Blog List</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a class="btn btn-sm btn-primary btn-block"
                                routerLink="/admin/blog/add">Add Blog</a></li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Blog List</h3>
                            <div class="card-tools">
                                <button (click)="resetSearch()" id="search_refresh" type="button"
                                    class="btn btn-primary">
                                    <i class="fas fa-redo-alt"></i>
                                </button>
                                <div class="input-group input-group-sm" style="width: 150px;">
                                    <input type="text" name="table_search" class="table_search form-control float-right"
                                        placeholder="Search By Title">
                                    <div class="input-group-append">
                                        <button type="submit" (click)="blogList(1)" class="btn btn-default">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="table-responsive" class="example-list mt-20">
                                <table datatable class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Drage Icon</th>
                                            <th>Title</th>
                                            <th>URL</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody cdkDropList class="example-list mt-20" (cdkDropListDropped)="drop($event)">
                                        <tr *ngIf="showLoader">
                                            <td class="loader-td" colspan="5">
                                                <i class="fas 3x fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                        <tr cdkDrag id="row_{{blog.id}}"
                                            *ngFor="let blog of List | paginate: { itemsPerPage: 50, currentPage: currentPage, id: 'second' ,totalItems: total } ; let i = index">
                                            <td>{{(i+1)+(50*(currentPage-1))}}</td>
                                            <td>
                                                <div class="w-10 drag-icon">
                                                    <i class="fas fa-bars"></i>
                                                </div>
                                            </td>
                                            <td>{{blog.title}}</td>
                                            <td class="description">{{blog. url}}</td>
                                            <td><img src="{{blog.image}}" width="100px" height="50px"></td>
                                            <td>
                                                <a class="btn btn-sm btn-outline-primary"
                                                    [routerLink]="['/admin/blog/view',blog.id]"><i
                                                        class="fas fa-eye"></i></a>
                                                <a class="btn btn-sm btn-outline-danger"
                                                    (click)="deleteBlog(blog.id)"><i class="fas fa-trash"></i></a>
                                                <a class="btn btn-sm btn-outline-info"
                                                    [routerLink]="['/admin/blog/edit',blog.id]"><i
                                                        class="fas fa-pencil-alt"></i></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="List?.length == 0" class="alert-danger">
                                            <td class="message-td" colspan="6">
                                                <span>
                                                    No data found
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination-controls (click)="getCurrentPage()" (pageChange)="chandlePageChange($event)"
                            id="second"></pagination-controls>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>