
        <div class="">
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-info">
                                <div class="card-header">
                                    <h3 class="card-title">Blog</h3>
                                </div>
                                <div class="card-body">
                                    <p><strong>Title:</strong>{{blogeDetail.title}}</p>
                                    <p><strong>Description:</strong>{{blogeDetail.description}}</p>
                                    <p><strong>Image:</strong><img [src]="blogeDetail.image"  style="height: 100px; width:100px"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       